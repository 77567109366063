import React from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'theme/utils/functions';

import SEO from 'components/common/SEO';
import Menu from 'components/common/Menu';
import Footer from 'components/common/Footer';
import Box from 'components/foundation/layout/Box';

const BetPageWrapper = ({
  agent,
  children,
  pageBoxProps,
  customLogoTag,
  seoProps = {},
  injectProps = [],
  layoutappview = false,
  menuProps = { menus: {}, display: true },
  footerProps = { cssProps: {}, display: true },
}) => {
  const { footerProps: _, ...safePageBoxProps } = pageBoxProps || {};

  // eslint-disable-next-line no-eval
  const customEval = s => eval(s);

  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      // @TODO: Melhorar essa configuração para propriedades enviadas pela página;
      const obProps = {};
      injectProps.forEach(p => {
        obProps[p] = customEval(p);
      });
      return React.cloneElement(child, { ...obProps });
    }
    return child;
  });

  return (
    <React.Fragment>
      <SEO {...seoProps} />

      <Box
        data-agent={agent}
        {...safePageBoxProps}
      >
        {menuProps.display ? (
          <Menu
            pageUri={menuProps?.uri}
            customLogoTag={customLogoTag}
            contentData={menuProps?.contentData}
            data={menuProps?.menus?.['novo-menu-principal']}
          />
        ) : null}

        {isEmpty(injectProps) ? children : childrenWithProps}

        {footerProps.display ? (
          <Footer
            layoutappview={layoutappview}
            cssProps={footerProps?.cssProps}
            data={menuProps?.menus?.['menu-rodape']}
          />
        ) : null}
      </Box>
    </React.Fragment>
  );
};

BetPageWrapper.propTypes = {
  agent: PropTypes.string,
  layoutappview: PropTypes.bool,
  customLogoTag: PropTypes.string,
  pageBoxProps: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  injectProps: PropTypes.arrayOf(PropTypes.string),
  menuProps: PropTypes.shape({
    uri: PropTypes.string,
    display: PropTypes.bool,
    menus: PropTypes.shape({
      'menu-rodape': PropTypes.arrayOf(PropTypes.shape({})),
      'novo-menu-principal': PropTypes.arrayOf(PropTypes.shape({})),
    }),
    contentData: PropTypes.shape({}),
  }),
  seoProps: PropTypes.shape({
    title: PropTypes.string,
  }),
  footerProps: PropTypes.shape({
    display: PropTypes.bool,
    cssProps: PropTypes.shape({}),
  }),
};

export default React.memo(BetPageWrapper);
