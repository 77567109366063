import React, { useState, useCallback } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import PropTypes from 'prop-types';

import Logo from 'theme/Logo';
import spacings from 'theme/spacings';

import Text from 'components/foundation/Text';
import Grid from 'components/foundation/layout/Grid';
import VARIAVEIS_GLOBAIS from 'theme/utils/globalVariables';
import getSocialIcon from '../SocialMediaIcons/mapping';

import * as S from './styles';

const Footer = ({ data = {}, cssProps, layoutappview }) => {
  const maxColumnLength = 5;
  const menuData = Object.values(data);
  const menuLength = menuData.length;

  const currentYear = new Date().getFullYear();
  const [visibleList, setVisibleList] = useState(null);

  const toggleListVisibility = useCallback(
    index => setVisibleList(visibleList === index ? null : index),
    [visibleList]
  );

  return (
    <S.FooterWrapper
      as='footer'
      role='contentinfo'
      $customStyles={cssProps}
    >
      {layoutappview ? (
        <Grid.Container>
          <Grid.Row>
            <Grid.Col $colValue={{ xs: 12 }}>
              <S.GameArenaLogo>
                <Logo
                  width={180}
                  height={40}
                />
              </S.GameArenaLogo>
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      ) : (
        <Grid.Container>
          <Grid.Row>
            <Grid.Col $colValue={{ xs: 12, lg: 9 }}>
              <Grid.Row>
                {menuData?.map((item, index) =>
                  index < maxColumnLength ? (
                    <Grid.Col
                      key={`menu-${index}`}
                      $colValue={{
                        xs: 12,
                        lg: index === menuLength - 1 ? 4 : 2,
                      }}
                    >
                      <S.ListGroup
                        className={visibleList === index ? 'visible' : ''}
                      >
                        <Text
                          as='h5'
                          $variant={{ typography: 'bodyTextBold' }}
                          $csscolor='00'
                          onClick={() => toggleListVisibility(index)}
                          aria-expanded={visibleList === index}
                        >
                          {item?.title}
                        </Text>
                        <ul>
                          {item?.submenu?.map((linkItem, indexB) => (
                            <li key={`linkSubmenu-${indexB}`}>
                              <Link
                                target='_top'
                                prefetch={false}
                                href={linkItem?.url}
                              >
                                <Text
                                  as='span'
                                  $variant={{ typography: 'bodyText' }}
                                  $csscolor='500'
                                >
                                  {linkItem?.title}
                                </Text>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </S.ListGroup>
                    </Grid.Col>
                  ) : (
                    <React.Fragment key={`item-${index}`} />
                  )
                )}
              </Grid.Row>

              <Grid.Row>
                <Grid.Col
                  $colValue={{ xs: 12, lg: 2 }}
                  $cssDisplay='flex'
                  $alignContent='center'
                  $cssAlignItems='center'
                >
                  <Link
                    target='_top'
                    prefetch={false}
                    title='Termos de uso e condições do Game Arena'
                    href={`${VARIAVEIS_GLOBAIS.HOST_GAMEARENA}/termos-de-uso-e-condicoes/`}
                  >
                    <Text
                      as='span'
                      $csscolor='500'
                      $cssDisplay='block'
                      $variant={{ typography: 'bodyText' }}
                      $cssMargin={{ xs: `${spacings.md} 0`, lg: '0' }}
                    >
                      Termos de uso
                    </Text>
                  </Link>
                </Grid.Col>

                <Grid.Col
                  $colValue={{ xs: 12, lg: 4 }}
                  $cssDisplay='flex'
                  $alignContent='center'
                  $cssAlignItems='center'
                >
                  <Link
                    target='_top'
                    prefetch={false}
                    title='Políticas de privacidade do Game Arena'
                    href={`${VARIAVEIS_GLOBAIS.HOST_GAMEARENA}/politica-de-privacidade/`}
                  >
                    <Text
                      as='span'
                      $csscolor='500'
                      $variant={{ typography: 'bodyText' }}
                    >
                      Políticas de privacidade
                    </Text>
                  </Link>
                </Grid.Col>

                <Grid.Col $colValue={{ xs: 12, lg: 6 }}>
                  <S.AppDownload>
                    <Text
                      as='h6'
                      $csscolor='00'
                      $variant={{ typography: 'bodyTextBold' }}
                    >
                      Baixe o App Game Arena
                    </Text>
                    <Link
                      target='_blank'
                      rel='noopener noreferrer'
                      title='Baixar o App Game Arena na App Store'
                      href='https://apps.apple.com/ie/app/game-arena/id6478929837'
                    >
                      <Image
                        width={108}
                        height={36}
                        quality={100}
                        alt='Baixar na App Store'
                        src='/images/app-store-badge.png'
                      />
                    </Link>
                    <Link
                      target='_blank'
                      rel='noopener noreferrer'
                      title='Baixar o App Game Arena na Play Store'
                      href='https://play.google.com/store/apps/details?id=gg.gamearena.app&hl=pt_BR'
                    >
                      <Image
                        width={130}
                        height={36}
                        quality={100}
                        alt='Baixar na Play Store'
                        src='/images/play-store-badge.png'
                      />
                    </Link>
                  </S.AppDownload>
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
            <Grid.Col
              $colValue={{ xs: 12, lg: 3 }}
              $cssDisplay='flex'
              $cssFlexDirection='column'
              $cssJustifyContent='space-between'
            >
              <S.SocialMediaIcons>
                {VARIAVEIS_GLOBAIS.SOCIAL_LINKS.map(social => {
                  const Icon = getSocialIcon(social.name);

                  return Icon ? (
                    <Link
                      target='_blank'
                      href={social.url}
                      key={social.name}
                      rel='noopener noreferrer'
                      title={`${social.name} Game Arena`}
                      aria-label={`Acesse o perfil do Game Arena no ${social.name}`}
                    >
                      <Icon aria-label={social.name} />
                    </Link>
                  ) : null;
                })}
              </S.SocialMediaIcons>

              <S.GameArenaLogo>
                <Logo
                  width={180}
                  height={40}
                />
              </S.GameArenaLogo>
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      )}

      <S.Copyright>
        <Grid.Container>
          <Text
            as='p'
            $csscolor='500'
            $cssTextAlign='center'
            $variant={{ typography: 'bodyTextXXSmall' }}
          >
            Copyright © 2022 - {currentYear} Game Arena. Todos os direitos
            reservados.
          </Text>
        </Grid.Container>
      </S.Copyright>
    </S.FooterWrapper>
  );
};

Footer.propTypes = {
  cssProps: PropTypes.shape({}),
  layoutappview: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default React.memo(Footer);
