import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

import VARIAVEIS_GLOBAIS from 'theme/utils/globalVariables';

const defaultTitle =
  'As notícias mais quentes de Games e E-Sports | Game Arena';

const SEO = ({
  seo,
  robots = '',
  title = defaultTitle,
  url = VARIAVEIS_GLOBAIS.HOST_GAMEARENA,
  'og:type': ogType,
  'og:image': ogImage,
  'og:locale': ogLocale = '',
  'og:site_name': ogSiteName = '',
  'twitter:card': twitterCard,
  'twitter:site': twitterSite,
  'og:image:width': ogImageWidth,
  'og:image:height': ogImageHeight,
  canonical_url: canonicalUrl = '',
  'twitter:creator': twitterCreator,
  'article:publisher': articlePublisher,
  'og:image:secure_url': ogImageSecureUrl,
  'article:modified_time': articleModifiedTime = '',
  'article:published_time': articlePublishedTime = '',
  image = `${VARIAVEIS_GLOBAIS.HOST_GAMEARENA}/images/imagem-destaque.webp`,
  description = 'Explore o emocionante mundo dos games e e-sports na Game Arena. Suas últimas notícias, análises e entrevistas exclusivas estão aqui. Seja parte da comunidade gamer. Bem-vindo à sua casa para o melhor do e-sports!',
}) => (
  <>
    {seo ? (
      <Head>
        {seo?.head
          ? parse(seo.head, {
              replace: domNode => {
                if (domNode.name === 'title' && title !== defaultTitle) {
                  return null;
                }

                return domNode;
              },
            })
          : null}

        {title && title !== defaultTitle ? <title>{title}</title> : null}

        {seo?.jsonLd ? parse(seo.jsonLd) : null}

        {
          title !== defaultTitle ? (
            <>
              <meta
                name='title'
                content={title}
              />
            </>
          ) : null
        }

        {description && !seo?.head ? (
          <>
            <meta
              name='description'
              content={description}
            />
            <meta
              property='og:description'
              content={description}
            />
            <meta
              name='twitter:description'
              content={description}
            />
          </>
        ) : null}
        <link
          rel='alternate'
          type='application/rss+xml'
          href={`${VARIAVEIS_GLOBAIS.HOST_GAMEARENA}/feed/`}
          title={`Feed para Game Arena ${title !== defaultTitle ? `- ${title}` : ''}`}
        />
      </Head>
    ) : (
      <Head>
        {/* <!-- Primary Meta Tags --> */}
        <title>{title}</title>
        <meta
          name='title'
          content={title}
        />
        {robots ? (
          <meta
            name='robots'
            content={robots}
          />
        ) : null}
        <meta
          name='description'
          content={description}
        />

        {/* <!-- Open Graph / Facebook --> */}
        {ogLocale ? (
          <meta
            property='og:locale'
            content={ogLocale}
          />
        ) : null}

        {ogSiteName ? (
          <meta
            property='og:site_name'
            content={ogSiteName}
          />
        ) : null}
        {ogType ? (
          <meta
            property='og:type'
            content={ogType}
          />
        ) : null}
        <meta
          property='og:url'
          content={canonicalUrl || url}
        />
        <meta
          property='og:title'
          content={title}
        />
        <meta
          property='og:description'
          content={description}
        />
        <meta
          property='og:image'
          content={ogImage || image}
        />
        {ogImageSecureUrl ? (
          <meta
            property='og:image:secure_url'
            content={ogImageSecureUrl}
          />
        ) : null}
        {ogImageWidth ? (
          <meta
            property='og:image:width'
            content={ogImageWidth}
          />
        ) : null}
        {ogImageHeight ? (
          <meta
            property='og:image:height'
            content={ogImageHeight}
          />
        ) : null}

        {/* Article */}
        {articlePublishedTime ? (
          <meta
            property='article:published_time'
            content={articlePublishedTime}
          />
        ) : null}
        {articleModifiedTime ? (
          <meta
            property='article:modified_time'
            content={articleModifiedTime}
          />
        ) : null}
        {articlePublisher ? (
          <meta
            property='article:publisher'
            content={articlePublisher}
          />
        ) : null}

        {/* <!-- Twitter --> */}
        <meta
          property='twitter:card'
          content={twitterCard || 'summary_large_image'}
        />
        <meta
          property='twitter:url'
          content={canonicalUrl || url}
        />
        <meta
          property='twitter:title'
          content={title}
        />
        <meta
          property='twitter:description'
          content={description}
        />
        <meta
          property='twitter:image'
          content={ogImage || image}
        />
        {twitterSite ? (
          <meta
            property='twitter:site'
            content={twitterSite}
          />
        ) : null}
        {twitterCreator ? (
          <meta
            property='twitter:creator'
            content={twitterCreator}
          />
        ) : null}

        <link
          rel='alternate'
          title='Feed para Game Arena'
          type='application/rss+xml'
          href={`${VARIAVEIS_GLOBAIS.HOST_GAMEARENA}/feed`}
        />
      </Head>
    )}
  </>
);

SEO.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  robots: PropTypes.string,
  schema: PropTypes.shape(),
  'og:type': PropTypes.string,
  'og:image': PropTypes.string,
  'og:locale': PropTypes.string,
  description: PropTypes.string,
  canonical_url: PropTypes.string,
  'og:site_name': PropTypes.string,
  'twitter:card': PropTypes.string,
  'twitter:site': PropTypes.string,
  'og:image:width': PropTypes.number,
  'og:image:height': PropTypes.number,
  'twitter:creator': PropTypes.string,
  'article:publisher': PropTypes.string,
  'og:image:secure_url': PropTypes.string,
  'article:modified_time': PropTypes.string,
  'article:published_time': PropTypes.string,
  seo: PropTypes.shape({
    head: PropTypes.string,
    jsonLd: PropTypes.string,
  }),
};

export default SEO;
